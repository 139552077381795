html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/lato-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
       url('./assets/fonts/lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/lato-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/lato-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/lato-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/lato-v16-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/lato-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'),
       url('./assets/fonts/lato-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/lato-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/lato-v16-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/lato-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/lato-v16-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}


html, body {
  min-height: 100%;
  overflow:hidden;
}
/* this is for error dialog that can be visible before theme is applied */
.form-submit-panel {
  height:36px !important;
  width: 96px !important;
  font-size: 14px !important;
  box-shadow: 'none' !important;
  cursor: 'pointer' !important;
}
/* this is for error dialog that can be visible before theme is applied */
.form-cancel {
  background-color: #fff !important;
}

.App {
  min-height: 100%;
  height: 100vh;
  overflow: auto;
  background-color: #fff;
}

input[type='number'] {
  -moz-appearance:textfield;
}

/* Webkit browsers like Safari and Chrome */

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-ms-clear {
  display: none
} 